@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;700&family=Poppins:wght@400;500;600;700&display=swap");
@import "tailwindcss/tailwind.css";
/* stylelint-disable */

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc(-100% / 4)
    ); /* Adjust the translation value based on the number of duplicated cards */
  }
}

.animate-slide {
  animation: slide 40s linear infinite; /* Adjust the animation duration */
}

.animate-slide > * {
  flex-shrink: 0;
}

.select_hover:hover {
  background: #ffbf00;
}
:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  font-family: "Cormorant Garamond", serif;
}

.carousel .control-dots {
  width: fit-content;
  max-width: fit-content;
  height: 15px;
  max-height: fit-content;
  margin: 0 auto;
  padding: 0;
  padding-bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }

  .bg-contrast {
    @apply text-white shadow-lg;
    background-color: rgba(0, 0, 0, 1);
    backdrop-filter: blur(50px);
    mix-blend-mode: difference;
    /* background-clip: text; */
  }

  .text-ellipsis {
    @apply overflow-hidden whitespace-nowrap;
    text-overflow: ellipsis;
  }
}

:where(.form-container) {
  @apply flex items-center max-w-fit  border border-[#000];
}

.form-container > input {
  @apply flex-1 p-2 outline-none;
}

.form-container > div {
  @apply p-3 cursor-pointer;
}

.stories-carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.stories-carousel {
  overflow: hidden;
  width: 95%;
  margin: 0 auto;
  padding: 0px 15px;
}

.stories-container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  gap: 24px;
  align-items: center;
}

.stories-carousel-container button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffbf00;
  color: #000;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  min-width: 32px;
  width: 32px;
  min-height: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-items: center;
}

.stories-carousel-container .prev-btn {
  left: 5px;
}

.stories-carousel-container .next-btn {
  right: 5px;
}
